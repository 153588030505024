import { ShootStat } from "../types/model";;

export const headers: (string | undefined)[] = ["NAME", , , , , "7th Jan", "21st Jan", "4th Feb", "18th Feb", "3rd Mar", "7th Apr", "21st Apr", "5th May", "19th May", "2nd June", "16th June", "30th June", "7th July", "21st July", "4th Aug", "18th Aug", "1st Sept", "15th Sept", "6th Oct", "20th Oct", "3rd Nov", "17th Nov", "1st Dec", "15th Dec"];

export const results: (string | number | undefined)[][] = [
["ALLEN JOHN", "B", 51.7, 10, 517, , 51, 56, , 50, 43, 56, , 51, 53, , 51, , 49, , 57, , , , , , , , ]
,["ALLEN JAMES", "B", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,["AZZOPARDI MATT", "NM", 62.9, 1, 63, , 63, , , , , , , , , , , , , , , , , , , , , , ]
,["BARROW DAVID", "B", 48.3, 5, 241, , , 49, , 50, 40, 57, , , , 46, , , , , , , , , , , , , ]
,["BEALING DAVID", "UC", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,["BIRD ARTHUR", "UC", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,["BLUNDELL MARK", "NM", 22.9, 1, 23, , , , , , , , , , , 23, , , , , , , , , , , , , ]
,["BROOKS DAVID", "UC", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,["BROWN DAVID", "AAA", 76.9, 14, 1077, 60, 59, 73, 89, 79, 73, 79, 81, 80, , , 79, 74, 79, 83, 90, , , , , , , , ]
,["CAMERON DEAN", "A", 64.6, 10, 646, 69, 53, , 84, 56, , , , , 61, 60, 59, 67, 66, , 71, , , , , , , , ]
,["CARR-GOMM MAH", "NM", 42.9, 2, 86, , 43, 43, , , , , , , , , , , , , , , , , , , , , ]
,["CATLING RICHARD", "C", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,["CHECHOTKIN VLADYSLAV ", "AA", 79.1, 8, 633, 74, 67, , 83, 84, , 84, , , 80, 74, , 86, , , , , , , , , , , ]
,["CLARENDON VICTOR", "A", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,["COLE BERNARD", "B", 55.2, 12, 663, 51, 57, 66, , , 56, 60, 50, 67, , 53, 54, , 59, 39, 51, , , , , , , , ]
,["COPE NEALE", "AA", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,["CRAWFORD MARTIN", "NM", 42.9, 1, 43, , 43, , , , , , , , , , , , , , , , , , , , , , ]
,["CULLINGHAM SAM", "A", 62.5, 3, 188, , , , 56, 63, , , , , 69, , , , , , , , , , , , , , ]
,["CULLINGHAM TATE", "C", 16.4, 2, 33, , , , 27, , , , , , 6, , , , , , , , , , , , , , ]
,["DAWSON PETER", "A", 60.4, 11, 665, 56, 43, 69, 79, 50, , , 67, 77, , , 46, , 63, 49, 67, , , , , , , , ]
,["DEACON ROBERT", "NM", 53.8, 6, 323, , , , , , , , , 63, 54, 43, , , 47, 53, 63, , , , , , , , ]
,["DE SILVA RICKY", "B", 49.2, 13, 639, 48, 34, 31, 56, , 49, 64, 47, 56, 60, 54, , , 41, 46, 53, , , , , , , , ]
,["DHIR VARINDER", "A", 62.7, 15, 940, 64, 59, 63, 76, 51, , 77, 60, 70, 61, 57, 67, 66, 57, 47, 64, , , , , , , , ]
,["DUFFELL ERIC", "A", 59.0, 8, 472, 46, , 50, , 56, 57, 66, , 80, 51, , , , , , 66, , , , , , , , ]
,["DUKE NEIL", "A", 59.1, 11, 650, , 53, 51, 80, 57, 27, 61, 64, 64, 69, , 54, , 69, , , , , , , , , , ]
,["DYER PAUL", "C", 50.7, 15, 760, 53, 61, 54, 63, 47, 36, 56, 47, 51, 50, 50, 50, , 43, 47, 51, , , , , , , , ]
,["ESCOTT PAUL", "A", 64.0, 3, 192, , , , 69, , , , , 60, , , , , , , 63, , , , , , , , ]
,["ESCOTT RICHARD", "AA", 66.5, 16, 1064, 77, 70, 67, 74, 63, 51, 79, 70, 74, 56, 66, 63, 74, 61, 56, 63, , , , , , , , ]
,["ESCOTT TIM", "AAA", 77.4, 14, 1083, 70, 74, 84, 89, 77, 66, 86, 79, 77, 86, 81, 81, 70, , , 63, , , , , , , , ]
,["GAYWOOD LINDA", "C", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,["FARAZAMAND A", "UC", 40.0, 1, 40, 40, , , , , , , , , , , , , , , , , , , , , , , ]
,["FARAZAMAND J", "B", 56.4, 2, 113, 50, , , , , , , 63, , , , , , , , , , , , , , , , ]
,["HEALEY LES", "AA", 81.3, 6, 488, , , , , , , 81, , 89, , 67, 84, , 83, 83, , , , , , , , , ]
,["HIBBERT PAUL", "U", 82.9, 10, 829, 74, 87, 74, 86, 84, , , , 90, , 80, 80, 83, , 90, , , , , , , , , ]
,["HOLLOWAY NICK", "UC", 22.9, 1, 23, 23, , , , , , , , , , , , , , , , , , , , , , , ]
,["HOOLEY PETER", "C", 50.0, 2, 100, , , , , , , , , , 38, , , 62, , , , , , , , , , , ]
,["HEPWARTH CHRIS", "C", 44.3, 9, 398, , , 41, , 47, 41, 57, , , , , 39, 43, 43, 37, 50, , , , , , , , ]
,["HUTCHINGS WILLIAM", "UC", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,["HUTCHINGS LYNNE", "UC", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,["JACKSON COLIN", "C", 49.2, 9, 443, 46, 28, 44, 60, , 43, 54, , 54, 54, , , 60, , , , , , , , , , , ]
,["JAY CHRIS", "A", 69.1, 10, 691, , 66, , 71, 76, 56, , 73, 84, , 53, 59, , , 74, 80, , , , , , , , ]
,["MICHAEL JOHNSON", "B", 58.6, 1, 59, 59, , , , , , , , , , , , , , , , , , , , , , , ]
,["KING JONATHAN", "B", 67.0, 2, 134, 50, , , 84, , , , , , , , , , , , , , , , , , , , ]
,["MANUEL RAYNOR", "AA", 75.7, 13, 984, 71, 67, , 90, 83, 67, 86, 79, 71, 63, 76, , 71, 81, , 79, , , , , , , , ]
,["MARCHETTI ALAN", "B", 41.4, 1, 41, , , , , , , , , , , , , , , 41, , , , , , , , , ]
,["MORGAN-RUSSELL TIM", "NM", 43.6, 2, 87, , , , , , , , , , 43, 44, , , , , , , , , , , , , ]
,["OFFEN COLIN", "UC", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,["O'CONNIOR JOHN", "UC", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,["O'DRISCOLL GRAHAM", "A", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,["O'GORMAN ROY", "B", 52.9, 4, 212, 49, , , , , 43, 56, , , , , , , , , 64, , , , , , , , ]
,["O'NEIL JULIAN", "B", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,["PAYNE BARRY", "C", 50.5, 6, 303, , , 50, , , , , 57, 64, 50, , 44, , 37, , , , , , , , , , ]
,["PRENTICE ROBERT", "NM", 67.1, 3, 201, , , , , , , 71, , 71, 59, , , , , , , , , , , , , , ]
,["PVK", "B", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,["SEWELL WAYNE", "NM", 67.8, 7, 474, 59, , , , , 63, 73, , 83, 51, , , , 77, 69, , , , , , , , , ]
,["SHARP RUSSELL", "A", 58.4, 8, 467, , , 57, 73, , , , 56, , , , 49, 56, 54, 60, 63, , , , , , , , ]
,["SMALLRIDGE ROSS", "NM", 59.3, 2, 119, , , , , , , , , , 50, , , , , , 69, , , , , , , , ]
,["SMALLDRIDGE STEVE", "NM", 71.0, 6, 426, , , , , , , 73, , 73, 83, 63, , , 69, 66, , , , , , , , , ]
,["SOUTHALL IAN", "NM", 27.1, 1, 27, , , , , , , , , , , 27, , , , , , , , , , , , , ]
,["STANLEY ALEX", "UC", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,["STANLEY WARWICK ", "B", 40.2, 8, 322, , 29, , 46, , , 37, 39, 53, , 37, , , , 31, 50, , , , , , , , ]
,["SULLIVAN MARTIN", "A", 70.7, 8, 566, , 73, 76, 73, , , , , , 76, 67, 66, 67, , , 69, , , , , , , , ]
,["THOMAS DUNCAN", "B", 57.9, 14, 811, 50, , 61, 71, , 56, 63, 56, 50, 53, 46, 60, 66, 67, 54, 59, , , , , , , , ]
,["TOWNLEY JOHN", "C", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,["TRUSTHAM MATT", "A", 56.9, 6, 341, 53, , 64, , 57, , , , , , , 54, 47, 66, , , , , , , , , , ]
,["TURNER MICHAEL", "NM", 47.1, 1, 47, , , , , , , 47, , , , , , , , , , , , , , , , , ]
,["VAUGHAN PAYNE R", "NM", 32.1, 2, 64, , , 40, , , 24, , , , , , , , , , , , , , , , , , ]
,["VOWLER ALAN", "C", 44.8, 9, 403, , 41, 33, 66, , , 43, 54, , , 37, 50, , 39, 40, , , , , , , , , ]
,["WALL NICKY", "C", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,["WELLS COLIN", "A", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,["WESTON DAVE", "B", 67.6, 3, 203, , , , , , , 57, , , , , , , , 74, 71, , , , , , , , ]
,["WESTON JADEN", "NM", 78.6, 1, 79, , , , , , , , , , , , , , , , 79, , , , , , , , ]
,["WHITE GRAHAM", "UC", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,["WHITE ROGER", "C", 48.7, 13, 633, 48, 32, , 74, , 49, 58, 56, 37, 43, , 62, 34, 38, 49, 54, , , , , , , , ]
,["WILLIAMS SID", "UC", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , ]
,];

export const shootStats: ShootStat[] = [
{"numberOfEntrants": 24, "average": 55.8, "high": 77.0, "low": 23.0},
{"numberOfEntrants": 23, "average": 54.5, "high": 87.0, "low": 28.0},
{"numberOfEntrants": 23, "average": 56.3, "high": 84.0, "low": 31.0},
{"numberOfEntrants": 24, "average": 71.6, "high": 90.0, "low": 27.0},
{"numberOfEntrants": 18, "average": 62.8, "high": 84.0, "low": 47.0},
{"numberOfEntrants": 19, "average": 49.5, "high": 73.0, "low": 24.0},
{"numberOfEntrants": 26, "average": 64.7, "high": 86.0, "low": 37.0},
{"numberOfEntrants": 18, "average": 61.0, "high": 81.0, "low": 39.0},
{"numberOfEntrants": 25, "average": 67.6, "high": 90.0, "low": 37.0},
{"numberOfEntrants": 25, "average": 56.8, "high": 86.0, "low": 6.0},
{"numberOfEntrants": 22, "average": 54.7, "high": 81.0, "low": 23.0},
{"numberOfEntrants": 21, "average": 59.6, "high": 84.0, "low": 39.0},
{"numberOfEntrants": 16, "average": 64.1, "high": 86.0, "low": 34.0},
{"numberOfEntrants": 22, "average": 58.5, "high": 83.0, "low": 37.0},
{"numberOfEntrants": 21, "average": 56.6, "high": 90.0, "low": 31.0},
{"numberOfEntrants": 25, "average": 64.4, "high": 90.0, "low": 50.0},
];
